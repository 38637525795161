import React from 'react';

export default () => {
  return (
    <div className='drop-down-buttons-container'>
      <div className='drop-down-heading'>
        CHOOSE THE PLAN<span>THAT BEST FITS YOU</span>
      </div>
      <a href='/insurance/individual' className='insurance-button'>
        <div className='insurance-button-img'>
          <svg>
            <use xlinkHref='#individual-icon'>
              <link rel='stylesheet' href='/sprite.css'></link>
            </use>
          </svg>
        </div>
        <div className='insurance-button-text'>
          <div className='insurance-button-text-bold'>INDIVIDUAL</div>
          <div className='insurance-button-text-normal'>INSURANCE</div>
        </div>
        <div className='dropdown-chevron'>▼</div>
      </a>

      {/* 
        <div>
            <div className='buttons-container'>
                <div className='buttons-container-first-row'>
                    <a href='/insurance/individual' className='insurance-button'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#individual-icon'>
                                    <link rel='stylesheet' href='/sprite.css'></link>
                                </use>
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>INDIVIDUAL</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                    <a href='/insurance/group' className='insurance-button'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#group-icon' />
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>GROUP</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                    <a href='/insurance/life' className='insurance-button'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#critical-illness-2-icon' />
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>LIFE</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                </div>
                <div className='buttons-container-first-row'>
                    <a href='/medicare' className='insurance-button hidden-on-mobile'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#group-icon' />
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>MEDICARE</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                    <a href='/insurance/vision' className='insurance-button'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#vision-icon' />
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>VISION</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                    <a href='/insurance/dental' className='insurance-button'>
                        <div className='insurance-button-img'>
                            <svg>
                                <use xlinkHref='#dental-icon' />
                            </svg>
                        </div>
                        <div className='insurance-button-text'>
                            <div className='insurance-button-text-bold'>DENTAL</div>
                            <div>INSURANCE</div>
                        </div>
                    </a>
                </div>
            </div>

        </div> */}
    </div>
  );
};
